
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import './App.css';
import Button from './Button';
import NetworksPage from './Network';



const Home = () => {
    const [hover, setHover] = useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const size = isMobile ? '9vh' : '5vw';
  
  return (
    
      <div style={{ position: 'relative', width: '100%', maxWidth: '100vw', display: 'flex', flexDirection: isMobile ? 'column': 'row', justifyContent: 'center', alignItems: 'center' }} className='vh100'>
        
        <div style={hover ? {} : {
            
          animation: 'fadeIn 20s ease-in-out alternate infinite',
        }}>
            <img
                src="https://img.currents-andata.xyz/file/currents-images-prod/af30b2ac4295d4d980cea1480f0415d9bc891bd4.jpg"
                className="translatez0"
                style={{
                    width: size,
                    height: size,
                    margin: size / 2,
                    animation: hover ? '' : 'fadeIn2 2.9s ease-in-out alternate infinite',
                }}
                onMouseEnter={() => {setHover(true)}}
                onMouseLeave={() => {setHover(false)}}
            /></div>
            <div style={{height: 48, minHeight: 48}} />
            <div style={{width: '5vw', minWidth: '5vw'}} />
            <div style={hover ? {} : {
          animation: 'fadeIn 20s ease-in-out alternate infinite',
        }}>
            <img
                src="https://img.currents-andata.xyz/file/currents-images-prod/54d3cbfc3721815329c0c72d6871c699deca888c.jpg"
                className="translatez0"
                style={{
                    width: size,
                    height: size,
                    margin: size / 2,
                    animation: hover ? '' : 'fadeIn2 3.1s ease-in-out alternate infinite',
                }}
                onMouseEnter={() => {setHover(true)}}
                onMouseLeave={() => {setHover(false)}}
            />
            </div>
            <div style={{height: 48, minHeight: 48}} />
            <div style={{width: '5vw', minWidth: '5vw'}} />
            <div style={{display: 'flex', flexDirection: isMobile ? 'row' : 'column'}} >
            <div style={hover ? {} : {
          animation: 'fadeInI 20s ease-in-out alternate infinite',
        }}>
            <img
                src="https://img.currents-andata.xyz/file/currents-images-prod/5c3b12f5859b4026a75e58b223ef73b1d6eb2f71.jpg"
                className="translatez0"
                style={{
                    width: size,
                    height: size,
                    margin: size / 2,
                    animation: hover ? '' : 'fadeIn3 3.3s ease-in-out alternate infinite',
                    //animationDelay: ".5s",
                }}
                onMouseEnter={() => {setHover(true)}}
                onMouseLeave={() => {setHover(false)}}
            />
            </div>
            <div style={{width: 48, minWidth: 48}} />
            <div style={{height: '5vw', minHeight: '5vw'}} />
            <img
                src="https://currents.fm/logo_30x.png"
                className="translatez0"
                style={{
                    width: size,
                    height: size,
                    margin: size / 2,
                    animation: hover ? '' : 'fadeIn2H 3.3s ease-in-out alternate infinite',
                }}
                onMouseEnter={() => {setHover(true)}}
                onMouseLeave={() => {setHover(false)}}
                onClick={() => {
                  window.open('https://a.currents.fm', '_blank')
              }}
            />
            <div style={{width: 48, minWidth: 48}} />
            <div style={{height: '5vw', minHeight: '5vw'}} />
            <div style={hover ? {} : {
          animation: 'fadeInI 20s ease-in-out alternate infinite',
        }}>
            <img
                //src="https://img.currents-andata.xyz/file/currents-images-prod/de650a2c4b9500cdeb38dca997333f3901c07bb3.jpg"
                //https://img.currents-andata.xyz/file/currents-images-prod/67ceda643f7a959d6752dde7bd5452c66e9724db.jpg
                //https://img.currents-andata.xyz/file/currents-images-prod/85cf01bd1bd50ae0eaecb8cf46fdb6397a9b5434.jpg
                src="https://img.currents-andata.xyz/file/currents-images-prod/e476114e63075ccfc4e3bfc89df817de234a66e2.jpg"
                className="translatez0"
                style={{
                    width: size,
                    height: size,
                    margin: size / 2,
                    animation: hover ? '' : 'fadeIn3 3.3s ease-in-out alternate infinite',
                    //animationDelay: ".5s",
                }}
                onMouseEnter={() => {setHover(true)}}
                onMouseLeave={() => {setHover(false)}}
            />
            </div>
            </div>

            <div style={{height: 48, minHeight: 48}} />
            <div style={{width: '5vw', minWidth: '5vw'}} />
            <div style={hover ? {} : {
          animation: 'fadeIn 20s ease-in-out alternate infinite',
        }}>
            <img
                src="https://img.currents-andata.xyz/file/currents-images-prod/fffdecb23e08c18476fbc0ad7ef4c60c68b9fcd0.jpg"
                className="translatez0"
                style={{
                    width: size,
                    height: size,
                    margin: size / 2,
                    animation: hover ? '' : 'fadeIn2 3.5s ease-in-out alternate infinite',
                }}
                onMouseEnter={() => {setHover(true)}}
                onMouseLeave={() => {setHover(false)}}
            />
            </div>
            <div style={{height: 48, minHeight: 48}} />
            <div style={{width: '5vw', minWidth: '5vw'}} />
            <div style={hover ? {} : {
          animation: 'fadeIn 20s ease-in-out alternate infinite',
        }}>
            <img
                src="https://img.currents-andata.xyz/file/currents-images-prod/d645317dbbbb4c0abc87744c24714c401a5164a1.jpg"
                className="translatez0"
                style={{
                    width: size,
                    height: size,
                    margin: size / 2,
                    animation: hover ? '' : 'fadeIn2 3.7s ease-in-out alternate infinite',
                }}
                onMouseEnter={() => {setHover(true)}}
                onMouseLeave={() => {setHover(false)}}
            />
            </div>

            <div style={{position: 'absolute', top: 0,left: 0, width: '50vw', height: '100%', display: 'flex', flexDirection: 'row', //center', justifyContent: 'center',
             //color: 'white', zIndex: 5
            }}>
                <div style={{//height: '100vh', 
                width: isMobile ? '100%' : '720px', minWidth: isMobile ? 'auto' : '720px', padding: isMobile ? 24 : 48, textAlign: 'left', display: 'flex', flexDirection: 'column',}}>

                {/* <div style={{fontSize: 20}}>RECURRENT LABS</div>
                <div style={{height: 24, minHeight: 24}} /> */}
                
                {/* <div style={{height: 48, minHeight: 48}} /> */}
                <div style={{display: 'flex', flexDirection: isMobile ? "column" :  'column', pointerEvents: 'auto'}}>
                <Button  link="https://recurrent.org/letter" text="LETTER [2022]" />
                <Button  link="https://careers.recurrent.org/" text="CAREERS"/>
                
                {/* <Button  link="https://recurrent.org/seedpr" text="PRESS RELEASE" /> */}
                
                
                </div>
                {/* <div>COMING SOON</div> */}
                <div style={{flex: 1}} />
                {/* <div style={{position: 'relative', width: '64px'}} className='rotateee'>
                <img src='https://currents.fm/logo_30x.png' style={{width: 64, position: 'absolute', top: 0, left: 0, opacity: .54, transform: 'translateZ(20px)'}}  />
                <img src='https://currents.fm/logo_30x.png' style={{width: 64, position: 'absolute', top: 0, left: 0, opacity: .54, transform: 'translateZ(-20px)'}}  />
                <img src='https://currents.fm/logo_30x.png' style={{width: 64, position: 'absolute', top: 0, left: 0, opacity: .54, transform: 'translateZ(0px)'}}  />
                </div> */}
                
                <div className="txt textStroke"  style={{color: 'black', pointerEvents: 'auto'}}> 
                <div style={{fontSize: 10, fontWeight: 800, color: 'black'}}>BETA COMING SOON</div>
                    Recurrent Labs is building an open protocol {isMobile ? ' ' : <br />} and <span onClick={() => {
                    window.open('https://currents.fm', '_blank')
                }} className="lonkk">platform</span> for collectively owned networks for{isMobile ? ' ' : <br />}music distribution and discovery - together, Currents.</div>
</div>
{/* <div style={{height: '100vh', width: '50%'}}>


<div>

</div>

                </div> */}
            </div>
        </div>
    
  );
}

export default Home;


 





