import { useMediaQuery } from 'react-responsive'
const ARROW_COLOR = "#e82755"
import VenusRoseRecurrent from './VenusRoseRecurrent';
import Diagram from './Diagram';
import './Page.css';


const lines =         [
    
    "⋅.˳˳.⋅ॱ˙˙ॱ⋅.˳˳.⋅ॱ˙˙ॱ⋅.˳˳.⋅",
    " ",
    "CONDUCTION",
    "- TRUST",
    "- CONDUCTION KEY",
    "- CONDUCTION THREAD",
    "- PROPERTIES",
    "-- CREATION",
    "-- DEVICES",
    "-- CYCLICALITY",
    "-- BREAKING TRUST",
    "-- EXITING",
    "CONTENT",
    "- CONTENT GRAPH",
    "- ACCESS",
    "- PUBLISHING",
    "- TYPES & METADATA",
    "EMERGENCE",
    "- GROUPS",
    "- BROWSING",
    "- PAIRING",
    "TRANSMUTATION",
    "- TRANSMUTATION GRAPH",
    "-- ENERGY",
    "-- LOSS",
    "-- DISTRIBUTION MESH",
    "DISTRIBUTION",
    "- COST OF DISTRIBUTION",
    "- ANNEALING",
    "- COLLECTIVES",
    "ROUTING",
    "- FOG",
    "- PROGRESSIVE FEE",
    "- COMPOUNDING FEE",
    "- ROUTING NODES", 
    "FLOW",
    "- DISTRIBUTION FLOW",
    "- FLOW CURATION",
    "- FLOW INTERDEPENDENCE",
    "- FLOW RESILIENCE",
    "- NYOP",
    "- TRUST DENSITY",
    "CONSENSUS",
    "- GRAPH TOKENS",
    "- NETWORK TOKENS",]


const Page = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
    const widthCol = 320
    const widthMargin = 32

    const colstyle = isMobile ? {
        width: 'calc(50vw - 24px)',
        height: 'calc(100% - 32px)',
        overflow: 'hidden',
        marginbottom: 16,
        
    } : {
        width: 320, 
        height: 'calc(100% - 64px)',
        marginTop: widthMargin, overflow: 'hidden'
    }

    const colstyle_right = isMobile ? {
        width: 'calc(50vw - 24px)',
        height: 'calc(100% - 52px)',
        //marginTop: 16,
        marginbottom: 16,
        backgroundColor: 'white', 
        border: '2px solid black', 
        padding: 8, 
        fontSize: 12, 
        fontWeight: 900, 
        textAlign: 'left', 
        overflow: 'hidden'
    } : {
        width: widthCol - widthMargin, 
        height: 'calc(100% - 84px)', 
        backgroundColor: 'white', 
        border: '2px solid black', 
        padding: 8, 
        marginTop: widthMargin,  
        fontSize: 16, 
        fontWeight: 900, 
        textAlign: 'left', 
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }

    return (

      <>
    <div style={{position: 'absolute',  overflow: 'hidden', width: '100vw', height: '100dvh',mixBlendMode: 'normal', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}} >
      <div style={{flex: 1}} />
      <div style={colstyle} id="maincol_left">
        <div style={{//position: 'absolute', // width: '100vw', 
            width: isMobile ? '100%' : '320px',
          height: isMobile ? '80dvh' : '100dvh',mixBlendMode: 'normal', pointerEvents: 'auto'}} id="rose">
          <VenusRoseRecurrent isMobile={isMobile} />
        </div>
      </div>
      <div style={{width: isMobile ? 8 : widthMargin}} />
      <div style={colstyle_right} className='overpass'
        id="words"
      >
    [RECURRENT ∞ CURRENTS]<br /><br />
      ATTACK/ the circle that opens space<br />
      DECAY/ the line that closes them<br />
      SUSTAIN/ the threads as we move in time<br />
      RELEASE/ the current follows to renew them<br />
      <br />
        {lines.map((line, i) => {
            return <div key={'line'+i} style={{filter: i > 4 ? `blur(${0.1*(i-4)}px)` : ''}}>{line}</div>
        })}
        
        

      </div>
      <div style={{flex: 1}} />

    </div>
    
    <div style={{position: 'absolute', mixBlendMode: 'normal', pointerEvents: 'all'}} >
      <Diagram />
    </div>
    </>
    )
    
}

export default Page;