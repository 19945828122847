import React from 'react';


const Button = props => {
    return <div style={{backgroundColor: 'rgba(255,255,255,0)',  borderRadius: 0,  cursor: 'pointer', padding: 12, borderRadius: 8, border: '1px solid white'}} className="button widget-glass textStroke" onClick={() => {
        window.open(props.link, '_blank')
    }}>
        {props.text}
    </div>
}

export default Button