import logo from './logo.svg';
import { useMediaQuery } from 'react-responsive'
import './App.css';
import Home from './Home';


import Page from './Page';


function App() {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  
  
  return (
    <div className="App" style={{backgroundColor: 'black', position: 'relative'}}>
      {/* <NetworksPage /> */}

      <Page />
      
      <div style={{position: 'absolute', width: '100%', height: '100%', top: 0, left: 0,pointerEvents: 'none'}}>
        <Home />
      </div>
    </div>
  );
}

export default App;
