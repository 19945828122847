import React, { useState } from 'react';
import { useRef, useEffect } from 'react';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { ArrowArea } from "react-arrow-master";
import ReactCurvedText from 'react-curved-text';
import { useCallback } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  Position,
} from 'reactflow';

import 'reactflow/dist/style.css';


import FloatingEdge from './FloatingEdge'
import { createNodesAndEdges } from "./utils.js";
const ARROW_COLOR = "#e82755"
const ARROW_COLOR_2 = "#fd7e14"

const HORIZ_SPACE = 72;
const VERT_SPACE = 96;



const edgeTypes = {
    floating: FloatingEdge
  };


  
  const { nodes: initialNodes, edges: initialEdges } = createNodesAndEdges();



const Diagram = () => {
    


    
    const [hover, setHover] = useState(false);
  
    // useEffect(() => {
    //     let isMounted = true;
    //     let heartbeatState = true; // Start with heartbeat true
    //     let intervalId;
      
    //     const startHeartbeat = (duration) => {
    //       intervalId = setInterval(() => {
    //         if (!isMounted) return;
      
    //         setHeartbeat(heartbeatState);
    //         clearInterval(intervalId); // Clear the current interval
    //         heartbeatState = !heartbeatState; // Toggle the heartbeat state
    //         startHeartbeat(heartbeatState ? 5000 : 3000); // Recursive call with new duration
    //       }, duration);
    //     };
      
    //     startHeartbeat(1000); // Start with heartbeat true for 1 second
      
    //     return () => {
    //       isMounted = false;
    //       clearInterval(intervalId);
    //     };
    //   // Only run this effect once, on mount
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    //   }, []);
  
    // useEffect(() => {
    //     // Start with heartbeat set to true
    //     setHeartbeat(true);
      
    //     // Function to toggle heartbeat
    //     const toggleHeartbeat = () => {
    //       setHeartbeat(prev => !prev);
    //     };
    //     // Set an interval to toggle heartbeat every 2 seconds
    //     const intervalId = setInterval(() => {
    //       toggleHeartbeat();
    //     }, 2000);
    //     return () => {
    //       clearInterval(intervalId);
    //     };
      
    //   }, []);

// ${heartbeat ? '' : ''}
  const box1Ref = useRef(null);

    

    return <div className={`overpass portalHolder ${hover ? 'showNet' : 'hideNet'}`} style={{backgroundColor: 'transparent', color: 'black', width: '100vw', height: '100dvh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        
        
        
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            <div ref={box1Ref} id="from"> </div>
            <div style={{opacity: 0}}>is the ground that defines how we meet</div>
            <div id="to"> </div>
        </div>
        

        <ArrowArea
            arrows={[
            //{ from: "el1", to: "el2", style: { color: "blue" } },
            {
                from: "circ1",
                to: "circ0",
                style: {
                    color: ARROW_COLOR,
                    width: 4,
                    head: "disc",
                    arrow: "smooth",
                    position: 'absolute'
                },
                
            },
            {
                from: "circ1",
                to: "circ3",
                style: {
                    color: ARROW_COLOR,
                    width: 4,
                    head: "disc",
                    arrow: "smooth",
                    position: 'absolute'
                },
                
            },
            // {
            //     from: {id: "circ3", posX: "middle", posY: "bottom"},
            //     to: {id: "circ1", posX: "middle", posY: "bottom"},
            //     style: {
            //         color: ARROW_COLOR,
            //         width: 4,
            //         head: "disc",
            //         arrow: "smooth",
            //         position: 'absolute'
            //     },
                
            // },
            {
                from: {id: "conductioncirc", posX: "middle", posY: "top"},
                to: "circ2",
                style: {
                    color: ARROW_COLOR,
                    width: 4,
                    head: "disc",
                    arrow: "smooth",
                    
                },
                
            },
            //crossbar - curves

            {//nth 4
                from: {id: 'circ1', posX: 'middle', posY: "top"},
                to: {id: 'conductioncirc', posX: 'middle', posY: 'bottom'},
                style: {
                    //color: 'blue',
                    width: 4,
                    head: "none",
                    arrow: "smooth",
                },
            },

            {//nth 5
                from: {id: 'circ3', posX: 'middle', posY: "top"},
                to: {id: 'conductioncirc', posX: 'middle', posY: 'bottom'},
                style: {
                    //color: 'blue',
                    width: 4,
                    head: "none",
                    arrow: "smooth"//"arcCounterclockwise",
                },
            },
            // {
            //     from: {id: "circ3", posX: "middle", posY: "bottom"},
            //     to: {id: "conductionStart", posX: 'middle', posY: 'bottom'},
            //     style: {
            //         color: 'transparent',
            //         width: 4,
            //         head: "none",
            //         arrow: "arcClockwise",
            //     },
                
            // },
            // {
            //     from: {id: "circ1", posX: "middle", posY: "bottom"},
            //     to: {id: "conductionEnd", posX: 'middle', posY: 'bottom'},
            //     style: {
            //         color: 'transparent',
            //         width: 4,
            //         head: "none",
            //         arrow: "arcCounterclockwise",
            //     },
                
            // },
            // {
            //     from: {id: "circ1", posX: "middle", posY: "top"},
            //     to: {id: "conductioncirc", posX: 'middle', posY: 'bottom'},
            //     style: {
            //         color: 'transparent',
            //         width: 4,
            //         head: "none",
            //         arrow: "smooth",
            //     },
                
            // },
            { //6 - bottom rect to rect2
                from: {id: "rec4", posX: "middle", posY: "top"},
                to: {id: "circ2", posX: 'middle', posY: 'bottom'},
                style: {
                    color: ARROW_COLOR,
                    width: 4,
                    head: "none",
                    arrow: "smooth",
                    strokeDasharray: 5,
                },
                
            },
            //middle circle => conduction start & end
            {//7 
                from: {id: "circ2", posX: "middle", posY: "top"},
                to: {id: "conductionStart", posX: 'middle', posY: 'bottom'},
                style: {
                    color: ARROW_COLOR,
                    width: 4,
                    head: "none",
                    arrow: "smooth",
                    position: 'absolute'
                },
                
            },
            { //8
                from: {id: "circ2", posX: "middle", posY: "top"},
                to: {id: "conductionEnd", posX: 'middle', posY: 'bottom'},
                style: {
                    color: ARROW_COLOR,
                    width: 4,
                    head: "none",
                    arrow: "smooth",
                },
                
            },
            // COLUMN - middle rect => conduction startend
            {//9
                from: {id: "rec4", posX: "right", posY: "top"},
                to: {id: "conductionStart", posX: 'right', posY: 'bottom'},
                style: {
                    color: ARROW_COLOR,
                    width: 4,
                    head: "none",
                    arrow: "smooth",
                    position: 'absolute'
                },
                
            },
            {//10
                from: {id: "rec4", posX: "left", posY: "top"},
                to: {id: "conductionEnd", posX: 'left', posY: 'bottom'},
                style: {
                    color: ARROW_COLOR,
                    width: 4,
                    head: "none",
                    arrow: "smooth",
                },
                
            },
            //PAYMENT - middle circle to startend - 
            {//11
                from: {id: "circ2", posX: "middle", posY: "bottom"},
                to: {id: "conductionStart", posX: 'middle', posY: 'bottom'},
                style: {
                    color: ARROW_COLOR,
                    width: 4,
                    head: "none",
                    arrow: "arcCounterclockwise",
                    position: 'absolute'
                },
                
            },
            {//12
                from: {id: "circ2", posX: "middle", posY: "bottom"},
                to: {id: "conductionEnd", posX: 'middle', posY: 'bottom'},
                style: {
                    color: ARROW_COLOR,
                    width: 4,
                    head: "none",
                    arrow: "arcClockwise",
                },
                
            },

            // {
            //     from: {id: "rec2_a", posX: "right", posY: "top"},
            //     to: {id: "conductionEnd", posX: 'middle', posY: 'bottom'},
            //     style: {
            //         color: ARROW_COLOR,
            //         width: 4,
            //         head: "none",
            //         arrow: "arcClockwise",
            //     },
            // },
            // {
            //     from: {id: "rec2_a", posX: "left", posY: "top"},
            //     to: {id: "conductionEnd", posX: 'middle', posY: 'bottom'},
            //     style: {
            //         color: ARROW_COLOR,
            //         width: 4,
            //         head: "none",
            //         arrow: "arcClockwise",
            //     },
            // },

            //FLOW - BOTTOM LEFTSIDE ARROW
            {//13
                from: {id: 'rec1_a', posX: 'right', posY: "middle"},
                to: {id: 'rec4', posX: 'middle', posY: 'top'},
                style: {
                    color: ARROW_COLOR,
                    width: 4,
                    head: "none",
                    arrow: "arcClockwise",
                },
            },
            {//14
                from: {id: 'rec1_a', posX: 'right', posY: "bottom"},
                to: {id: 'rec4', posX: 'right', posY: 'top'},
                style: {
                    color: ARROW_COLOR,
                    width: 4,
                    head: "none",
                    arrow: "arcClockwise",
                    
                },
            },
            //FLOW - BOTTOM RIGHTSIDE ARROW
            {//15
                from: {id: 'rec3_a', posX: 'left', posY: "middle"},
                to: {id: 'rec4', posX: 'middle', posY: 'top'},
                style: {
                    color: ARROW_COLOR,
                    width: 4,
                    head: "none",
                    arrow: "arcCounterclockwise",
                },
            },
            {//16
                from: {id: 'rec3_a', posX: 'left', posY: "bottom"},
                to: {id: 'rec4', posX: 'left', posY: 'top'},
                style: {
                    color: ARROW_COLOR,
                    width: 4,
                    head: "none",
                    arrow: "arcCounterclockwise",
                },
            },
            //16th onwards
            {//17
                from: {id: 'rec3_a', posX: 'middle', posY: "middle"},
                to: {id: 'circ3', posX: 'middle', posY: 'middle'},
                style: {
                    color: ARROW_COLOR,
                    width: 4,
                    head: "none",
                    arrow: "smooth",
                },
            },
            {//18
                from: {id: 'rec2_a', posX: 'middle', posY: "middle"},
                to: {id: 'circ2', posX: 'middle', posY: 'middle'},
                style: {
                    color: ARROW_COLOR,
                    width: 4,
                    head: "none",
                    arrow: "smooth",
                },
            },
            {//19
                from: {id: 'rec1_a', posX: 'middle', posY: "middle"},
                to: {id: 'circ1', posX: 'middle', posY: 'middle'},
                style: {
                    color: ARROW_COLOR,
                    width: 4,
                    head: "none",
                    arrow: "smooth",
                },
            },
            {//20
                from: {id: 'circ1', posX: 'middle', posY: "middle"},
                to: {id: 'conductionStart', posX: 'middle', posY: 'middle'},
                style: {
                    //color: 'blue',
                    width: 4,
                    head: "none",
                    arrow: "smooth",
                },
            },
            {//21
                from: {id: 'circ3', posX: 'middle', posY: "middle"},
                to: {id: 'conductionEnd', posX: 'middle', posY: 'middle'},
                style: {
                    //color: 'blue',
                    width: 4,
                    head: "none",
                    arrow: "smooth",
                },
            },

            ]}
        >


            <div style={{ display: "flex",  width: '100vw', justifyContent: "space-evenly", flexDirection: 'column', alignItems: 'center', justifyContent: 'center' , }}>
            
            <div style={{width: 320, height: 320, borderRadius: 320, position: 'relative',}} 
                className='portal'
                onMouseOver={() => {
                    console.log(hover)
                    setHover(true)
                }}
                onMouseOut={() => {
                    console.log(hover)
                    setHover(false)
                }}
                onTouchStart={() => {
                    setHover(true)
                }}
                onTouchEnd={() => {
                    setHover(false)
                }}
            >
                <div id="conductioncirc" style={{width: 320, height: 320, borderRadius: 320, margin: -16, border: '16px solid white', position: 'absolute',}} />
                <div className="conduction_grad_0" style={{width: 322, height: 322, borderRadius: 320,  mixBlendMode: 'darken', position: 'absolute'}} />
                <div className="conduction_grad" style={{width: 322, height: 322, borderRadius: 320,  position: 'absolute', mixBlendMode: 'difference'}} />
                <div  style={{width: 400, height: 400, borderRadius: 320, margin: -40,  position: 'absolute'}} className="rotate360 textStroke mirror">
                    <ReactCurvedText
                        width={400}
                        height={400}
                        cx={200}
                        cy={200}
                        rx={180}
                        ry={180}
                        startOffset={50}
                        reversed={false}
                        text="MUSIC LIVES BETWEEN THE THREADS THAT FORM IN THE SPACE BETWEEN US"
                        textProps={{ style: { fontSize: 24, color: 'transparent', fill: 'rgba(255,255,255,.2)', fontWeight: 900, letterSpacing: 1, } }}
                        textPathProps={null}
                        tspanProps={null}
                        ellipseProps={null}
                        svgProps={null}
                    />
                </div>
                <div  style={{width: 448, height: 448, borderRadius: 320, margin: -64,  position: 'absolute', mixBlendMode: 'lighten'}} className="rotate360_r textStroke mirror">
                    <ReactCurvedText
                        width={448}
                        height={448}
                        cx={224}
                        cy={224}
                        rx={204}
                        ry={204}
                        startOffset={50}
                        reversed={false}
                        text="MUSIC LIVES BETWEEN THE THREADS THAT FORM IN THE SPACE BETWEEN US"
                        textProps={{ style: { fontSize: 24, color: 'white', fill: 'rgba(255,255,255,.2)', fontWeight: 900, letterSpacing: 1, } }}
                        textPathProps={null}
                        tspanProps={null}
                        ellipseProps={null}
                        svgProps={null}
                    />
                </div>

                <div style={{width: 360, height: 360 ,margin: -20, position: 'absolute', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mixBlendMode: 'exclusion'}} className="inter">
                    <div style={{marginTop: 84}} />
                    <div className="loader_inff" />
                    
                </div>
                <div style={{width: 360, height: 360 ,margin: -20, position: 'absolute', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mixBlendMode: 'exclusion'}} className="inter">
                    <div style={{marginTop: 128}} />
                        
                        <div className="loader_cblob" />
                    </div>

                    <div style={{width: 360, margin: -20, top: 240,position: 'absolute'}} className="inter">
                        
                        <div style={{opacity: 0}}><span id="conductionStart"/> is the ground that defines how we meet<span id="conductionEnd"/></div>
                        
                </div>
            </div>

            {/* <div id="el1" style={{ marginBottom: 20 }}>
                ○
            </div>
            <div id="el2" style={{ marginBottom: 20 }}>
                ○
            </div>
            <div id="el3" style={{ marginTop: 20 }}>
                ○
            </div> */}
            </div>
        
            <div style={{height: VERT_SPACE}} />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                
                <div id='circ1' className='net_circ widget-glass transition_03' />
                <div style={{width: HORIZ_SPACE}} />
                <div
                    id='circ2' 
                    className='widget-glass transition_03 net_circ '
                    style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', fontWeight: 900, color: 'black', fontSize: 12,}}
                >
                    

                <div  style={{width: 96, height: 96, borderRadius: 96, margin: -24,  position: 'absolute', mixBlendMode: 'normal'}} className="rotate360_r textStroke mirror">
                    <ReactCurvedText
                        width={96}
                        height={96}
                        cx={48}
                        cy={48}
                        rx={36}
                        ry={36}
                        startOffset={0}
                        reversed={false}
                        text="YOU ARE HERE - YOU ARE HERE - "
                        textProps={{ style: { fontSize: 10, color: 'black', fill: 'black', fontWeight: 900, letterSpacing: 1.4, } }}
                        textPathProps={null}
                        tspanProps={null}
                        ellipseProps={null}
                        svgProps={null}
                    />
                </div>
                </div>
                <div style={{width: HORIZ_SPACE}} />
                <div id='circ3' className='widget-glass transition_03 net_circ' />
                
                
            </div>

            <div style={{height: VERT_SPACE}} />

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <div id='rec1_a' className='widget-glass net_rect'>
                    
                </div>
                <div style={{width: HORIZ_SPACE, height: 32, backgroundColor: 'transparent'}} id='rec0_x'/>
                <div id='rec2_a' className='widget-glass net_rect '
                 style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',  fontWeight: 900, color: 'black', fontSize: 12,}}
                >
                
                    </div>
                <div style={{width: HORIZ_SPACE, height: 32, backgroundColor: 'transparent'}} id='rec00_x'/>
                <div id='rec3_a' className='widget-glass net_rect'/>
            </div>

            <div style={{height: VERT_SPACE}} />

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',  }}>
                <div id='rec4' className='widget-glass net_rect textStroke' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',  fontWeight: 900, color: 'black', fontSize: 12,}}>
                    THE CURRENT
                </div>
            </div>

        </ArrowArea>

        {/* <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            edgeTypes={edgeTypes}
            >
             <MiniMap />
            <Controls /> 
            <Background />
            </ReactFlow> */}

{/* <Xarrow
        start='circ3' //can be react ref
        end="rec2_2" //or an id
        curveness={0.999}
        color={'#ffffff'}
        showHead={true}
        
        headSize={4}
        tailSize={4}
        startAnchor={'top'}
        endAnchor={'left'}
    />
    <Xarrow
            start='circ2' //can be react ref
            end="rec1_1" //or an id
            curveness={0.999}
            color={'#ffffff'}
            showHead={true}
            
            headSize={4}
            tailSize={4}
            startAnchor={'top'}
            endAnchor={'left'}
        />
        <Xarrow
                start='circ2' //can be react ref
                end="rec2_1" //or an id
                curveness={0.999}
                color={'#ffffff'}
                showHead={true}
                
                headSize={4}
                tailSize={4}
                startAnchor={'top'}
                endAnchor={'right'}
            />

        <Xarrow
                start='circ1' //can be react ref
                end="rec1_0" //or an id
                curveness={0.999}
                color={'#ffffff'}
                showHead={true}
                
                headSize={4}
                tailSize={4}
                startAnchor={'top'}
                endAnchor={'right'}
            />

        <div>

        <Xarrow
                start={box1Ref} //can be react ref
                end="to" //or an id
                color={'#ffffff'}
                showHead={true}
                showTail={true}
                headSize={4}
                tailSize={4}
            /> */}

        {/* </div> */}
        
    </div>
}

export default Diagram