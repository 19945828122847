import React, { useRef, useMemo, useState, useEffect } from 'react';

import { Canvas, useThree } from '@react-three/fiber';
import { Line, Torus, PerspectiveCamera, OrbitControls, Sphere } from '@react-three/drei';
import * as THREE from 'three';

const LINE_COLOR = 'white'// "#e82755"

const rotatePoint = (point, angleDegrees, axis) => {
  const angleRadians = (angleDegrees * Math.PI) / 180;
  let rotationMatrix;

  switch (axis) {
    case 'X':
      rotationMatrix = [
        [1, 0, 0],
        [0, Math.cos(angleRadians), -Math.sin(angleRadians)],
        [0, Math.sin(angleRadians), Math.cos(angleRadians)],
      ];
      break;
    case 'Y':
      rotationMatrix = [
        [Math.cos(angleRadians), 0, Math.sin(angleRadians)],
        [0, 1, 0],
        [-Math.sin(angleRadians), 0, Math.cos(angleRadians)],
      ];
      break;
    case 'Z':
      rotationMatrix = [
        [Math.cos(angleRadians), -Math.sin(angleRadians), 0],
        [Math.sin(angleRadians), Math.cos(angleRadians), 0],
        [0, 0, 1],
      ];
      break;
    default:
      throw new Error('Invalid axis. Use "X", "Y", or "Z".');
  }

  const rotatedPoint = [
    point[0] * rotationMatrix[0][0] + point[1] * rotationMatrix[0][1] + point[2] * rotationMatrix[0][2],
    point[0] * rotationMatrix[1][0] + point[1] * rotationMatrix[1][1] + point[2] * rotationMatrix[1][2],
    point[0] * rotationMatrix[2][0] + point[1] * rotationMatrix[2][1] + point[2] * rotationMatrix[2][2],
  ];

  return rotatedPoint;
};


const GenericSphereComponent = ({
  index,
  position,
  setSphereRef,
  setSpherePosition,
}) => {
  const meshRef = useRef(null);
  const { camera } = useThree();
  useEffect(() => {
    if (meshRef.current) {
      const worldPosition = new THREE.Vector3();
      meshRef.current.getWorldPosition(worldPosition);
      const screenPosition = worldPosition.clone().project(camera);
      setSpherePosition(index, worldPosition, screenPosition);
    }
    setSphereRef(index, meshRef.current);
  }, [camera, setSphereRef, setSpherePosition]);

  // return <div />
  return (
    <Sphere position={position} args={[0.08, 10, 10]} ref={meshRef}>
      <meshBasicMaterial attach="material" color="transparent" />
    </Sphere>
  );

  // <mesh ref={meshRef}>
  //     <sphereGeometry args={[0.08, 16, 16]} position={position} />
  //     <meshBasicMaterial color="black" wireframe={true}  wireframelinewidth={2} />
  // </mesh>
};

const caluclateVP = (theta: number, z: number, rotations: number) => {
  const venusOrbitRadius = 0.723; // Relative scale for Venus's orbit
  const earthOrbitRadius = 1; // Relative scale for Earth's orbit
  const venusPeriod = 0.615; // Venus orbital period relative to Earth's
  const earthPeriod = 1; // Earth's orbital period (normalized to 1 year)

  const earthTheta = (theta * rotations) / earthPeriod;
  const venusTheta = (theta * rotations) / venusPeriod;

  const thetaOffset = 0; //20/2 * Math.PI/180

  // Calculate positions in their orbits
  const earthX = earthOrbitRadius * Math.cos(earthTheta + thetaOffset);
  const earthY = earthOrbitRadius * Math.sin(earthTheta + thetaOffset);
  const venusX = venusOrbitRadius * Math.cos(venusTheta + thetaOffset);
  const venusY = venusOrbitRadius * Math.sin(venusTheta + thetaOffset);

  const dist = Math.sqrt(Math.pow(venusX - earthX, 2) + Math.pow(venusY - earthY, 2));
  const opacity = 1 - dist / 1.7320508075688772;
  const color = new THREE.Color().setHSL(330 / 360, 0.8, opacity / 2);

  if (isNaN(venusX + venusY + earthX + earthY)) return false;
  const point = new THREE.Vector3(venusX - earthX, venusY - earthY, z);
  return point;
};

const BlendedLine = (points: THREE.Vector3[]) => {
  return (
    <Line
      points={points} // Array of THREE.Vector3
      color={LINE_COLOR}
      lineWidth={2}
    >
      <lineBasicMaterial
        attach="material"
        color={LINE_COLOR}
        blending={THREE.AdditiveBlending} // Set the blend mode here
        transparent={true} // Enable transparency for blending to work
        opacity={0.6} // Adjust opacity as needed
      />
    </Line>
  );
};

const useVenusPath = (z: number, rotations: number) => {
  return useMemo(() => {
    const points = [];
    const steps = 10000; // Increase for smoother curve

    for (let i = 0; i <= steps; i++) {
      const theta = (2 * Math.PI * 8 * i) / steps;

      // Calculate Venus's position relative to Earth
      const point = caluclateVP(theta, z, rotations);
      if (point) {
        points.push(point);
      }
    }
    return points;
  }, [z, rotations]); // Add dependencies to ensure memoization works correctly
};

const PathLine = ({ z }) => {
  return (
    <Line
      points={useVenusPath(z, 1)} // array of Vector3
      color={LINE_COLOR}
      opacity={1 - Math.abs(z) * 3}
      //opacity={0.33}         // Opacity (transparency)
      transparent // Enable transparency
      lineWidth={2}
    />
  );
};

const OrbitVisualizer = ({ setSpherePosition }) => {
  const sphereRefs = useRef([]);
  const setSphereRef = (index, ref) => (sphereRefs.current[index] = ref);

  return (
    <>
      {[-0.3, -0.2, -0.1, 0, 0.1, 0.2, 0.3].map((z) => (
        <PathLine z={z} 
          //key={'pathz' + z} 
        />
      ))}
      {/* <Torus args={[33, 0.04, 16, 100]} position={[0, 0, 33]} rotation={[Math.PI / 2, 0, 0]}>
        <meshBasicMaterial
          attach="material"
          color="deepskyblue"
          opacity={0.33} // Opacity (transparency)
          transparent // Enable transparency
        />
      </Torus> */}
      {[0, 1, 2, 3, 4, 5].map((pointN) => {
        return (
          <GenericSphereComponent
            //key={'roseTrack' + pointN}
            position={rotatePoint([-0.38, 0, 0], 72 * pointN, 'Z')}
            index={pointN}
            setSphereRef={setSphereRef}
            setSpherePosition={setSpherePosition}
          />
        );
      })}
      <GenericSphereComponent
        position={[0, 0, 0]}
        index={6}
        setSphereRef={setSphereRef}
        //key={'roseTrack' + 6}
        setSpherePosition={setSpherePosition}
      />
    </>
  );
};



const VenusRoseRecurrent = ({isMobile}) => {
  const [tracks, setTracks] = useState({});
  const setSpherePosition = (index, worldPosition, screenPosition) => {
    const screenX = (screenPosition.x * 0.5 + 0.5) * 320 + (window.innerWidth/2 - 320);
    const screenY = (screenPosition.y * -0.5 + 0.5) * window.innerHeight+32;
    setTracks((prevState) => ({ ...prevState, [index]: { x: screenX, y: screenY } }));
  };

  return (
    <div className="relative" style={{width: '100%', height: '100%'}}>
      <Canvas id="myCanvas" style={{ width: '100%', height: '100dvh', backgroundColor: 'black' }}>
        <PerspectiveCamera makeDefault position={[0, 0, 2.2]} fov={80} />
        <OrbitControls autoRotate autoRotateSpeed={0.2} />
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <group position={[-0, .3, -0]}>
          <OrbitVisualizer setSpherePosition={setSpherePosition} />
        </group>
      </Canvas>
      {Object.entries(tracks).map(([key, value]) => {
        if (isMobile) return null;
        return (
          <div
            key={'track' + key}
            className="widget-glass  p-2  textStroke text-label color-black"
            style={{ top: value?.y, left: value?.x, color: 'black', position: 'absolute', borderRadius: 4, border: '1px solid white', padding: 6}}
          >
            TRACK {key}
          </div>
        );
      })}
    </div>
  );
};

export default VenusRoseRecurrent;
